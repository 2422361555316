<template>
  <div class="top-menu-list ms-15">
    <ul class="list-inline mt-4">
      <li class="list-inline-item me-10">
        <a
          @click="routeToPage('curators')"
          :class="[
            'social-icon text-xs-center cursor-pointer',
            $route.path === '/curators' ? 'active' : '',
          ]"
          >Curators</a
        >
      </li>
      <li class="list-inline-item me-10">
        <a
          @click="routeToPage('playlists')"
          :class="[
            'social-icon text-xs-center cursor-pointer',
            $route.path === '/playlists' ? 'active' : '',
          ]"
          >Playlists</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { routeToPage } from "@/utils/staticHelper";

export default {
  name: "topMenuList",
  methods: { routeToPage },
  setup() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.top-menu-list {
  .list-inline {
    .list-inline-item {
      .social-icon {
        color: #33647f;
        font-size: 12px;
        font-weight: 600;
        .list-icon {
          font-size: 2rem;
          fill: #33647f;
        }
      }
      .active {
        color: #009ef7 !important;
      }
      .social-icon:hover {
        color: #009ef7 !important;
      }
      .list-icon:hover {
        fill: #009ef7 !important;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .top-menu-list {
    .list-inline {
      .me-10 {
        margin-right: 1rem !important;
      }
    }
  }
}
.el-dropdown-menu__item:hover {
  color: #009ef7 !important;
}
</style>
