<template>
  <div
    class="d-flex align-items-center ms-1 ms-lg-3"
    id="kt_header_search_menu_toggle"
  >
    <div
      class="cursor-pointer symbol symbol-30px symbol-md-40px"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="bottom"
    >
      <svg
        version="1.1"
        viewBox="0 0 25 25"
        height="30px"
        width="30px"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
        <title xmlns="http://www.w3.org/2000/svg">
          Search Curators / Artists / Playlist
        </title>
        <desc xmlns="http://www.w3.org/2000/svg">Created with Sketch.</desc>
        <defs xmlns="http://www.w3.org/2000/svg"></defs>
        <g
          xmlns="http://www.w3.org/2000/svg"
          id="Stockholm-icons-/-General-/-Search"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <rect id="bound" x="0" y="0" width="30" height="30"></rect>
          <path
            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
            id="Path-2"
            fill="#33647f"
            fill-rule="nonzero"
            opacity="0.3"
          ></path>
          <path
            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
            id="Path"
            fill="#33647f"
            fill-rule="nonzero"
          ></path>
        </g>
      </svg>
    </div>
    <div
      class="
        menu
        menu-sub
        menu-sub-dropdown
        menu-column
        menu-rounded
        menu-gray-600
        menu-state-bg-light-primary
        fw-bold
        py-4
        fs-6
        mw-350px
      "
      data-kt-menu="true"
    >
      <div class="menu-item px-5 p-3">
        <div class="b-dropdown-text">
          <span class="font-size-lg theme-v2-color">
            Search Artist / Curator / Track / Playlist
          </span>
        </div>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item px-5 my-2">
        <el-select
          class="form-control form-control-solid"
          filterable
          placeholder="Select Search Type"
          v-model="searchType"
        >
          <el-option label="Artist" value="artist"></el-option>
          <el-option label="Curator" value="curator"></el-option>
          <el-option label="Track" value="track"></el-option>
          <el-option label="Playlist" value="playlist"></el-option>
        </el-select>
      </div>
      <div class="menu-item px-5 my-2">
        <div class="search-bar me-0">
          <input
            class="form-control form-control-solid"
            :placeholder="'Artist, Curator, Track, Playlist'"
            v-model="searchQuery"
            v-on:keyup.enter="search"
            :disabled="searchType === ''"
          />
          <svg
            class="search-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            v-on:click="search"
          >
            <path
              d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
            />
          </svg>
        </div>
      </div>
      <div class="separator my-2"></div>
      <div class="menu-item px-5">
        <button
          class="btn btn-sm btn-primary btn-style w-100 ms-0"
          v-on:click="search"
          :disabled="
            searchType === '' || searchQuery === '' || searchQuery === null
          "
        >
          Search {{ capitalizeFirstLetter(searchType) }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
import {
  capitalizeEveryWordFirstLetter,
  capitalizeFirstLetter,
} from "@/utils/staticHelper";
export default {
  name: "SearchBar2",
  methods: { capitalizeFirstLetter },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const searchQueryURL = computed(() => {
      return route.query.search;
    });
    const searchQuery = ref("");
    const searchType = ref("");
    const search = () => {
      if (searchQuery.value && searchType.value) {
        router.push(
          `/explore?type=${searchType.value}&search=${searchQuery.value}`
        );
      }
    };
    watch(
      () => searchQueryURL.value,
      (value) => {
        if (value) {
          searchQuery.value = value;
          searchType.value = route.query.type;
        }
      },
      { immediate: true }
    );
    return {
      searchQuery,
      searchType,
      search,
    };
  },
};
</script>
<style scoped>
input::placeholder {
  color: #33647f !important;
  font-size: 12px;
  font-weight: 400;
}
.search-bar {
  position: relative;
  width: -webkit-fill-available;
  margin-right: 1rem;
}
.search-icon {
  position: absolute;
  right: 2%;
  font-size: 24px;
  bottom: 25%;
  fill: #33647f;
}
.btn-style {
  margin-left: 12px;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.search-icon:hover {
  color: #009ef7 !important;
}
</style>
