
import { defineComponent } from "vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import TopSearchField from "@/common/components/TopSearchField.vue";
export default defineComponent({
  name: "topbar",
  components: {
    KTQuickLinksMenu,
    // KTUserMenu,
    TopSearchField,
  },
});
